.NavbarItems{
    background: linear-gradient(90deg, rgb(110, 94, 254), rgba(73, 63,252,1) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}
.navbar-logo{
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
}
.fa-react{
    margin: 0.5rem;
    font-size: 1.6rem;
}


.nav-menu{
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-links{
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
    
}

.nav-links:hover{
    background-color: #6d76f7;
    border-radius:10px ;
    transition: all 0.2s ease-out;
}

.nav-links-mobile{
    display: none;
}
.menu-icon{
    display: none;
}
.fa-bars{
    color: #fff;
}


@media screen and (max-width: 960px){
    .navbarItems{
        position: relative;
    }

    .nav-menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 700px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active{
        background: #6668f4;
        left:0;
        opacity: 1;
        transition: all 0.5 ease;
        z-index: 1;
    }

    .nav-links{
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover{
        background-color: #7577fa;
        border-radius: 0;
    }

    .navbar-logo{
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%,50%);
    }

    .menu-icon{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%,60%);
        font-size: 0.5rem;
        cursor:pointer;
    }

    .fa-times{
        color:#fff;
        font-size: 2rem;
    }

    .fa-bars{
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobile{
        display: block;
        text-align: center;
        padding: 1rem;
        margin: 2rem auto;
        border-radius: 10px;
        width:50%;
        background: #4ad9e4;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
    }

    .nav-links-mobile:hover{
        background: #fff;
        color: #6568f4;
        transition: 250ms;
    }

    Button{
        display: none;
    }
}